import React from 'react';
import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import NoCover from "./no-cover";
import Figure from "../components/figure/figure";
import Head from 'components/head';

import { extractAuthorNames } from '../helpers/utils';

export const BookSectionWrapper = styled.div `
.book-section {
 background-color: #f9f9f9;
}
.gatsby-image-wrapper{
 position: static;
}
.book-card {
 display: flex;  
 background-color: #ffffff;
 margin: 50px auto;
 max-width: 800px;
 width: 100%; 
 flex-direction: column;
 
 .small-img{
  object-fit: scale-down;
 }
 .normal-img{
  object-fit: contain;
  ${MEDIA.TABLET`
    object-fit: scale-down;
  `};
 }
 figcaption{
  opacity: 0;
 }
 > .image {
   flex: 0 0 10%;
   position: relative;
   overflow: hidden;
   display: flex;
   padding: 50px 0;
   justify-content: center;
   ${MEDIA.TABLET`
     padding: 0;
   `};
   &:before {
     filter: blur(10px);
     content: ''; 
     background: url(https://unsplash.it/400/300);
     background-size: cover;
     display: block;
     width: 120%;
     height: 120%;
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
     z-index: 1;
   }
 }
 
 > .image > .content {
   z-index: 2;
   position: relative; 
 }
 
 > .text {
   background-color: white;
   flex: 0 0 50%;
 }
 
}

.book-text {
 padding: 32px;
 
 > .genre {
   display: block;
   font-family: 'Open Sans', sans-serif;
   font-size: 12px;
   font-weight: 700;
   margin-bottom: 32px;
 }
 
 > .heading {
   color: #0a2832;
   font-size: 25px;
   font-weight: 600;
   margin-bottom: 3px;
   line-height: 1.3;
 }
 
  > .author {
     font-weight: 700;
     font-size: 14px;
     line-height: 1.5;
     margin-bottom: 3px;
   }
 
   > .rating {
     color: #8c5701;
     margin-bottom: 30px;
   }
 
   > .description {
     color: #a9a9a9;
     line-height: 2;
     font-family: 'Lato', sans-serif;
     font-size: 12px;
     font-weight: 700;
   }
}

.footer {
 margin-top: 50px;
 display: flex;
 justify-content: flex-end;
}

.button {
 background-color: #00e0ad;
 color: #000000;
 font-family: 'Lato', sans-serif;
 font-size: 12px;
 font-weight: 700;
 padding: 16px 20px;
 letter-spacing: 0.5px;
 align-text: center;
 border-radius: 3px;

 &:hover {
   background-color: #efefef;
   color: #10d1a5;
   cursor: pointer;
 }
}

.book-meta {
 color: white;
 position: absolute;
 bottom: 35px;
 z-index: 2;
 display: flex;
 justify-content: center;
 font-family: 'Crimson Text', serif;
 font-size: 12px;
 width: 100%;
}

.meta-item {
 display: flex;
 flex-direction: row-reverse;
 
 &:not(:last-child) {
   margin-right: 16px;
 }
 
 > .icon {
   font-size: 14px;
   margin-right: 8px;
 }
}

.book-cover {
 width: 90%;
 margin: 0 auto;
 padding-bottom: 12px;
 
 > img {
   width: 100%;
   min-width: 300px;
   height: auto;
 }
}

.book-description {
 text-align: center;
 color: #40d3b4;
 font-family: 'Crimson Text', serif;
 font-size: 14px;
}
`;

export const BookDetails = (book) => {
 return (
   
  <BookSectionWrapper>
   <section className='book-section'>
    <div className='book-card'>
     <div className='image'>
      <div className='content'>
       <div className='book-cover'>
       {
          book.image_url.indexOf("nophoto")>0 ?
          <NoCover/>
          :
          <Figure small={book.small_image_url} normal={book.large_image_url} title={book.title}/>
        }
       </div>
      </div>
     </div>
     <div className='text book-text'>
      <span className='genre'>
       Fiction / Fiction & Literature
      </span>
      <h2 className='heading'>
      {book.title}
      </h2>
      { book.original_title ? <h3>{book.original_title}</h3> : '' }
      { book.title_without_series ? <h3>{book.title_without_series}</h3> : '' }
      <div className='author'>
       by {extractAuthorNames(book.authors)}
      </div>
      <div className='rating'>
       { Array.from({length: Math.min(Math.round(book.average_rating), 5)}).map((i, k)=> '★') }
       { book.ratings_count == 0 ? 23 : book.ratings_count } votes
       </div>
      <article className='description' dangerouslySetInnerHTML={{
                __html: book.description,
              }}/>
      <footer className='footer'>
       <a className='button' href={`/download/?file=${book.title || book.original_title}&size=${Math.floor(Math.random()*20)}&pages=${book.num_pages || Math.floor(Math.random()*400)}`}>
        Open the Book
       </a>
      </footer>
     </div>
    </div>
   </section>
  </BookSectionWrapper>
 );
}
export default BookDetails;