/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import { Title } from 'components/gallery/item/item.css';
const Img = styled.img`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  opacity: 0;
  transition-delay: 500ms;
`;
const Img2 = styled.img`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  opacity: 1;
  transition: opacity 500ms ease 0s;
`;
const ImgWrapper = styled.div`
  position:relative;overflow:hidden
`;
const Figure = ({small, normal, title})=>{
  return (
  <figure>
    <ImgWrapper className="gatsby-image-wrapper">
      <div className="img-wrapper" style={{width:"100%",paddingBottom:"66.66666666666667%"}}></div>
        <Img className="small-img" src={small || normal || '/static/nocover.jpg'} alt={title} loading="eager" width={318} height={475}/>
        <picture>
          <Img2 className="normal-img" sizes="(max-width: 750px) 100vw, 750px" src={normal || small || '/static/nocover.jpg' } alt={title} loading="lazy" width={318} height={475}/>
        </picture>
    </ImgWrapper>
    <figcaption>
    <Title>{title}</Title>
    </figcaption>
  </figure>)
}

export default Figure;