import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Title = styled.span`
  display: block;
  font-size: 2rem;
  font-weight: 500;
  margin: 2rem 2rem 1rem;
  color: #000;
  text-style: none;
`;

export const Copy = styled.p`
  color: #757575;
  margin: 0 2rem 2rem;

  ${MEDIA.TABLET`
    margin-bottom: 4rem;
  `};
`;
export const Img = styled.img`
  height: 30vh;
  width: auto;
  margin-right: 15px;
  box-shadow: 0 0 4px #000;
  ${MEDIA.TABLET`
    width: 100%;
    height: auto;
    max-width: 700px;
  `};
`;
